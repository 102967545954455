@media screen and (max-width: 750px) {
    .container {
        padding: 0 16px;
        width: 320px;
    }

    .grid {
        display: none;
        margin: 0 auto;
        max-width: 100%;

        &.grid-mobile {
            display: block;
        }

        .item {
            margin-bottom: 12px;
            margin-left: 4%;

            &.w1 {
                width: 48%;
            }

            &.w3 {
                width: 100%;
            }

            &.h1 {
                height: 138px;
            }

            &.h2 {
                height: 288px;
            }

            &.w3.h2 {
                height: 192px;
            }
        }
    }

    .smileIcon {
        display: none;
    }

    .header-wrapper {
        height: 49px;
        padding: 13px 0 0;

        .logo {
            margin: 0;

            svg {
                height: 27px;
                width: 95px;
            }
        }

        .burger {
            background: url(../img/burger.svg) no-repeat;
            background-size: cover;
            display: inline-block;
            float: right;
            height: 18px;
            margin-top: 5px;
            text-indent: -9999px;
            width: 26px;

            &.active {
                background-image: url(../img/ico-close-grey.svg);
                width: 17px;
            }
        }

        &.yellow {
            .burger {
                background-image: url(../img/burger-white.svg);

                &.active {
                    background-image: url(../img/ico-close.svg);
                }
            }
        }

        .menu {
            display: none;
        }

        .megamenu-wrapper {
            bottom: calc(100% - 49px);
            overflow: auto;
            top: 49px;

            .megamenu {
                > ul {
                    margin: 7vh 0 0;

                    > li {
                        @include font-size(37);
                        line-height: 1.22; // 45px
                        width: 100%;

                        &:nth-child(2n+1) {
                            margin-right: 0;
                            padding-left: 0;
                            width: 100%;
                        }

                        &:nth-child(2n) {
                            margin-right: 0;
                            padding-left: 0;
                            width: 100%;
                        }

                        > ul {
                            margin: 11px 0 13px;

                            > li {
                                @include font-size(15);
                                line-height: 2; // 30px
                            }
                        }
                    }
                }

                .close {
                    display: none;
                }
            }
        }

        .appointment-button {
            display: none;
        }
    }

    .offset {
        height: 49px;
    }

    .footer-wrapper {
        @include clearfix;
        padding: 23px 0 18px;

        .left {
            width: 100%;

            .logo {
                margin-bottom: 0;

                svg {
                    height: 19px;
                    width: 71px;
                }
            }

            p {
                display: none;
                @include font-size(12);
                line-height: 2; // 24px

                &.email {
                    display: inline-block;
                    float: right;
                }
            }
        }

        nav {
            display: none;
        }
    }

    .legal {
        @include font-size(11);
        line-height: 2; // 22px
        padding: 22px 0;
    }

    .services {
        padding: 12vh 0 0;

        &.bgYellow {
            padding-bottom: 40px;
            padding-top: 12vh;
        }

        .header {
            margin-bottom: 0;
            padding-bottom: 50px;

            h1 {
                @include font-size(40);
                line-height: 1.25; // 50px
                margin: 0 0 10px;

                &:before {
                    display: none;
                }

                &:after {
                    display: none;
                }
            }

            p {
                @include font-size(24);
                line-height: 1.25; // 32px
                max-width: 100%;
            }

            .icons {
                height: 30px;
                margin: 30px auto 0;
                width: 97px;
            }
        }

        .service {
            margin-top: 40px;

            .slider {
                li {
                    width: 288px;
                }
            }

            .bullets {
                margin: 26px 0 0;
                width: 200px;

                > a {
                    background-size: 17px 17px;
                    height: 35px;
                    margin-right: 15px;
                    width: 35px;

                    &.next {
                        margin-left: 15px;
                        margin-right: 0;
                    }
                }

                div {
                    padding: 10px 0;

                    a {
                        height: 15px;
                        margin: 0 5px;
                        width: 15px;
                    }
                }
            }

            .image {
                height: 141px;
                width: 181px;
            }

            h2 {
                @include font-size(28);
                line-height: 1.25; // 35px
                margin: 20px 0 9px;
                text-align: left;

                &:before {
                    display: none;
                }

                &:after {
                    display: none;
                }
            }

            p {
                @include font-size(16);
                line-height: 1.56; // 25px
                max-width: 100%;
                text-align: left;
            }

            .teeth {
                display: none;
                margin: 26px auto 0;
                width: 60px;

                span {
                    height: 11px;
                    margin: 0 5px;
                    width: 9px;

                    &.t2 {
                        height: 14px;
                        margin-top: -2px;
                        width: 12px;
                    }
                }
            }
        }
    }

    .team {
        margin-bottom: -22px;
        padding: 40px 0 0;

        .grid {
            display: block;

            .item {
                &.w3 {
                    .overlay {
                        padding: 24px 25px;

                        h3 {
                            @include font-size(25);
                            line-height: 1.28; // 32px
                        }

                        h4 {
                            @include font-size(12);
                            line-height: 1.75; // 21px
                            margin: 2px 0 0;
                        }

                        p {
                            display: none;
                        }
                    }
                }

                &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(5), &:nth-child(7), &:nth-child(11), &:nth-child(13), &:nth-child(15) {
                    float: left;
                    margin-left: 0;
                }

                &:nth-child(4), &:nth-child(6), &:nth-child(10), &:nth-child(14), &:nth-child(16) {
                    float: right;
                }

                &:nth-child(9), &:nth-child(12) {
                    display: none;
                }

                &.title {
                    padding: 37px 12px 0;

                    svg {
                        height: 26px;
                        width: 27px;
                    }

                    h1, h2 {
                        @include font-size(25);
                        line-height: 1.28; // 32px
                        margin: -1px 0 0;
                    }
                }

                .toggle {
                    background-size: 13px 15px;
                    bottom: 6px;
                    height: 32px;
                    right: 6px;
                    width: 32px;
                }

                .overlay {
                    padding: 19px 13px;

                    h3 {
                        @include font-size(13);
                        line-height: 1.46; // 19px
                    }

                    p {
                        @include font-size(11);
                        line-height: 1.82; // 20px
                    }

                    .more {
                        background-size: 9px 9px;
                        bottom: 6px;
                        height: 32px;
                        right: 6px;
                        width: 32px;
                    }
                }
            }
        }
    }

    .doctor {
        > .image {
            background-size: 345px auto;
            height: 231px;
            position: relative !important;
            right: 0 !important;
            top: 0 !important;
        }

        .info {
            padding-left: 0;
            width: 100%;

            h1 {
                @include font-size(30);
                line-height: 1.2; // 36px
                margin: 12px 0 0;
            }

            h2 {
                @include font-size(12);
                line-height: 2; // 24px
                margin: -2px 0 5px;
            }

            h3 {
                @include font-size(22);
                line-height: 1.45; // 32px
                margin: 0 0 10px;
                padding-left: 10px;

                &:before {
                    margin-left: -10px;
                    margin-right: 5px;
                }
            }

            p {
                @include font-size(16);
                line-height: 1.63; // 26px
                margin: 0 0 9px;
            }

            .list-services {
                .service {
                    margin: 12px 0 26px 42px;
                    width: 119px;

                    &:nth-child(2n+1) {
                        clear: both;
                        margin-left: 0;
                    }

                    .image {
                        height: 119px;
                        width: 119px;

                        &.diagnostico {
                            padding: 13px 0 0 25px;

                            svg {
                                height: 78px;
                                width: 60px;
                            }
                        }

                        &.tecnologia {
                            padding: 22px 0 0 17px;

                            svg {
                                height: 60px;
                                width: 78px;
                            }
                        }

                        &.ortodoncia {
                            padding: 45px 0 0 25px;

                            svg {
                                height: 23px;
                                width: 61px;
                            }
                        }

                        &.odontologia {
                            padding: 19px 0 0 28px;

                            svg {
                                height: 69px;
                                width: 53px;
                            }
                        }

                        &.atm {
                            padding: 28px 0 0 19px;

                            svg {
                                height: 56px;
                                width: 69px;
                            }
                        }

                        &.implantologia {
                            padding: 18px 0 0 32px;

                            svg {
                                height: 76px;
                                width: 47px;
                            }
                        }

                        &.endodoncia {
                            padding: 18px 0 0 28px;

                            svg {
                                height: 70px;
                                width: 51px;
                            }
                        }
                    }

                    h4 {
                        @include font-size(14);
                        margin: 14px 0 0;
                    }
                }
            }
        }
    }

    .gallery-doctor {
        margin-bottom: -15px;
        padding: 15px 0 0;

        .container {
            width: 300px;
        }

        .grid {
            .item {
                margin-bottom: 15px;
                margin-left: 5.67%;

                &.w1 {
                    width: 47.16%;
                }

                &.w3 {
                    width: 100%;
                }

                &.h1 {
                    height: 142px;
                }

                &.h2 {
                    height: 200px;
                }

                &:nth-child(2){
                    float: left;
                    margin-left: 0;
                }

                &:nth-child(3) {
                    float: right;
                }
            }
        }
    }

    .gallery {
        margin-bottom: -18px;
        padding: 74px 0 0;

        .grid {
            .item {
                &:nth-child(9n+1), &:nth-child(9n+3), &:nth-child(9n+4), &:nth-child(9n+6), &:nth-child(9n+8) {
                    margin-left: 0;
                }

                &:nth-child(9n+2), &:nth-child(9n+5), &:nth-child(9n+7), &:nth-child(9n) {
                    float: right;
                }
            }
        }
    }

    .about {
        padding-top: 0;

        .content {
            max-width: 100%;

            .timeline {
                display: none;
            }

            .info {
                margin: 40px 0 10px;
                width: 100%;

                .icon {
                    display: block;
                    margin-bottom: 10px;

                    svg {
                        height: 100%;
                        width: 100%;
                    }

                    &.icon1 {
                        height: 110px;
                        width: 122px;
                    }

                    &.icon2 {
                        height: 100px;
                        width: 121px;
                    }

                    &.icon3 {
                        height: 122px;
                        width: 126px;
                    }

                    &.icon4 {
                        height: 113px;
                        width: 92px;
                    }
                }

                p {
                    @include font-size(29);
                    line-height: 1.45; // 42px
                    margin: 0 0 40px !important;
                    padding-right: 0;

                    &:last-child {
                        margin: 0 !important;
                    }

                    a {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .contact {
        .content {
            max-width: 100%;

            .timeline {
                margin-top: 20px;
                padding-left: 0;
                width: 100%;

                .icon {
                    height: 66px;
                    margin-bottom: 0;
                    width: 75px;
                }

                .line {
                    display: none;
                }
            }

            .info {
                width: 100%;

                h3 {
                    @include font-size(16);
                    line-height: 1.67; // 24px
                    margin: 11px 0 2px;
                }

                h1, h2 {
                    @include font-size(29);
                    line-height: 1.17; // 34px
                    margin: 0 0 7px;
                }

                p {
                    @include font-size(16);
                    line-height: 1.63; // 26px
                }

                .columns {
                    margin-right: 0;
                    margin-top: 17px;
                    padding-bottom: 18px;

                    .col {
                        display: block;
                        float: none;
                        margin-bottom: 18px;

                        &.col1 {
                            width: 100%;
                        }

                        &.col2 {
                            margin-left: 0;
                            width: 100%;
                        }

                        &.col3 {
                            margin-left: 0;
                        }

                        h4 {
                            @include font-size(16);
                            line-height: 1.5; // 24px
                            margin: 0 0 -2px;
                        }

                        p {
                            @include font-size(16);
                            line-height: 1.5; // 24px
                        }
                    }
                }

                h2 {
                    margin-top: 29px;
                }
            }
        }

        #map_canvas {
            height: 265px;
            margin: 12px auto 0;
            width: 100%;
        }
    }

    .appointment {
        padding: 21px 0 0;

        h1 {
            @include font-size(34);
            line-height: 1.29; // 44px
            margin: 0 0 53px;
            text-align: left;
        }

        .form {
            background-color: #fff;
            margin: 0 0 0 -16px;
            padding-top: 55px;
            width: calc(100% + 32px);
        }

        .field {
            background-color: transparent;
            border-radius: 0;
            margin: 0;
            opacity: 1 !important;
            padding: 0 16px 25px;

            &:nth-child(2) {
                border-radius: 0;
            }

            &:last-child {
                border-radius: 0;
                padding-bottom: 23px;
            }

            .next {
                background-size: 20px 12px;
                bottom: -35px;
                height: 70px;
                left: calc(50% - 35px);
                width: 70px;
            }

            > label {
                @include font-size(23);
                line-height: 1.33; // 31px
                margin: 0 0 3px;
            }

            input, select, textarea {
                @include font-size(14);
                line-height: 1.5; // 21px
                padding: 15px 12px;
            }

            textarea {
                height: 51px;
            }

            .half {
                width: 100%;

                &:last-child {
                    margin-left: 0;
                    margin-top: 22px;
                }

                label {
                    @include font-size(16);
                    line-height: 1.3; // 21px
                    margin: -5px 0 10px;
                }
            }
        }
    }

    .appointment-footer {
        padding: 0 0 60px;

        p {
            @include font-size(24);
            line-height: 1.33; // 32px
            max-width: 100%;
            text-align: left;
        }

        label {
            @include font-size(12);
            line-height: 1.5; // 18px
            margin: 14px 0 19px;
            text-align: left;
        }
    }

    .static {
        padding-top: 0;

        .content {
            max-width: 100%;

            .timeline {
                display: none;
            }

            .info {
                padding-right: 0;
                width: 100%;

                h2 {
                    @include font-size(14);
                    font-weight: 400;
                    line-height: 1.71; // 24px
                    margin: 15px 0 -3px;
                }

                h1 {
                    @include font-size(29);
                    line-height: 1.17; // 34px
                    margin: 0 0 8px;
                }

                p, ul {
                    @include font-size(14);
                    line-height: 1.71; // 24px
                    margin: 0 0 25px;
                }

                h3 {
                    @include font-size(14);
                    line-height: 1.71; // 24px
                }

                table {
                    margin: 20px 0;
                }
            }
        }
    }

    .home {
        .quote-block {
            padding-top: 50px;
        }
    }

    .home-placeholder {
        height: calc(100vh - 49px);
    }

    .hero {
        padding: 27vh 0 0;
        top: 49px;

        .icons {
            height: 32px;

            svg {
                margin: 0 6px;
            }
        }

        h1 {
            @include font-size(19);
            margin: 27px 0 0;
        }

        .down {
            background-size: 20px 12px;
            bottom: 40px;
            height: 44px;
            left: calc(50% - 22px);
            width: 44px;
        }
    }

    @keyframes downButton {
        0% {
            bottom: 60px;
        }

        10% {
            bottom: 50px;
        }

        20% {
            bottom: 60px;
        }

        30% {
            bottom: 50px;
        }

        90% {
            bottom: 50px;
        }

        100% {
            bottom: 60px;
        }
    }

    .home-diagnosis {
        padding: 30px 0 40px;

        .icons {
            text-align: left;

            .icon {
                height: 79px;
                margin: 0 0 0 13px;
                width: 79px;

                &:first-child {
                    margin: 0;
                    padding: 17px 0 0 20px;

                    svg {
                        height: 37px;
                        width: 30px;
                    }
                }

                &:nth-child(2) {
                    padding: 14px 0 0 8px;

                    svg {
                        height: 40px;
                        width: 50px;
                    }
                }

                &:last-child {
                    padding: 18px 0 0 11px;

                    svg {
                        height: 35px;
                        width: 50px;
                    }
                }
            }
        }

        h2 {
            @include font-size(28);
            line-height: 1.43; // 40px
            margin: 9px auto 4px;
            max-width: 100%;
            text-align: left;
        }

        p {
            @include font-size(24);
            line-height: 1.33; // 32px
            margin: 0 auto 20px;
            max-width: 100%;
            text-align: left;
        }

        .btn {
            margin: 0;
        }
    }

    .home-gallery {
        padding-bottom: 50px;

        .grid {
            .item {
                &:nth-child(9n+1), &:nth-child(9n+3), &:nth-child(9n+5), &:nth-child(9n), &:nth-child(9n+7) {
                    float: left;
                    margin-left: 0;
                }

                &:nth-child(9n+2), &:nth-child(9n+4), &:nth-child(9n+6), &:nth-child(9n+8) {
                    float: right;
                }
            }
        }

        .more {
            background-size: 12px 12px;
            height: 45px;
            margin: 38px auto 0;
            width: 45px;
        }
    }

    .home-services {
        padding: 35px 0 45px;

        h2 {
            @include font-size(36);
            line-height: 1.28; // 46px;
            margin: 0 0 28px;

            &:before {
                display: none;
            }

            &:after {
                display: none;
            }
        }

        .list-services {
            max-width: 100%;

            .service {
                margin: 0 0 35px 46px;

                &:nth-child(2n+1) {
                    margin-left: 3px;
                }

                &:first-child {
                    .image {
                        padding: 13px 0 0 27px;

                        svg {
                            height: 77px;
                            width: 60px;
                        }
                    }
                }

                &:nth-child(2) {
                    .image {
                        padding: 22px 0 0 16px;

                        svg {
                            height: 59px;
                            width: 77px;
                        }
                    }
                }

                &:nth-child(3) {
                    .image {
                        padding: 46px 0 0 25px;

                        svg {
                            height: 23px;
                            width: 61px;
                        }
                    }
                }

                &:nth-child(4) {
                    .image {
                        padding: 20px 0 0 27px;

                        svg {
                            height: 69px;
                            width: 53px;
                        }
                    }
                }

                &:nth-child(5) {
                    .image {
                        padding: 18px 0 0 28px;

                        svg {
                            height: 70px;
                            width: 51px;
                        }
                    }
                }

                &:nth-child(6) {
                    .image {
                        padding: 17px 0 0 20px;

                        svg {
                            height: 71px;
                            width: 71px;
                        }
                    }
                }

                &:nth-child(7) {
                    .image {
                        padding: 18px 0 0 32px;

                        svg {
                            height: 76px;
                            width: 47px;
                        }
                    }
                }

                &:last-child {
                    .image {
                        padding: 19px 0 0 26px;

                        svg {
                            height: 69px;
                            width: 56px;
                        }
                    }
                }

                .image {
                    height: 117px;
                    width: 117px;
                }

                h3 {
                    @include font-size(14);
                    line-height: 1.5; // 21px
                    margin: 8px 0 0;
                }
            }
        }
    }

    .page404 {
        .container {
            margin-top: 40px;
            padding-bottom: 40px;
            padding-top: 10px;
        }

        .icon {
            height: 113px;
            margin: 10px auto 0;
            width: 146px;
        }

        h1 {
            @include font-size(189);
            line-height: 0.68;
        }

        p {
            @include font-size(22);
            line-height: 1.36; // 30px
            margin: 20px auto 20px;
            max-width: 100%;
        }
    }

    .gallery-wrapper {
        .images-container {
            margin-top: 16px;
        }

        .images {
            img {
                width: 288px;
            }
        }

        nav {
            margin: 20px 0;

            a {
                height: 50px;
                margin-left: 20px;
                width: 50px;

                &.prev, &.next {
                    background-size: 16px 16px;
                }

                &.close {
                    background-size: 12px 12px;
                }
            }
        }
    }

    .quote-block {
        .container {
            padding-bottom: 0;
            padding-top: 30px;

            .btn {
                display: none;
            }
        }

        p {
            border: none;
            @include font-size(28);
            line-height: 1.43; // 40px
            max-width: 100%;
            padding: 67px 0 0;
            text-align: left;

            &:before {
                background-size: 45px 44px;
                height: 44px;
                left: 0;
                padding: 0;
                top: 0;
                width: 45px;
            }
        }

        &.quote2 {
            .container {
                padding-bottom: 10vh;
                padding-top: 10vh;
            }

            p {
                border: none;
                @include font-size(36);
                line-height: 1.25; // 45px
                padding: 74px 0 0;

                &:before {
                    background-color: transparent;
                    height: 48px;
                    left: calc(50% - 24px);
                    width: 49px;
                }
            }

            h5 {
                @include font-size(14);
                line-height: 1.71; // 24px
                margin: 27px 0 80px;

                &:before {
                    margin-right: 5px;
                }

                &:after {
                    margin-left: 5px;
                }
            }

            .down {
                display: block;
            }
        }

        &.quote-about {
            p {
                margin: 0;
                max-width: 100%;
                padding: 70px 0 40px;
            }
        }

        &.drquote {
            .container {
                padding: 70px 0 76px;

                p {
                    border: none;
                    @include font-size(32);
                    line-height: 1.38; // 44px
                    padding: 63px 0 0;

                    &:before {
                        background-color: transparent;
                        height: 48px;
                        left: calc(50% - 24px);
                        width: 49px;
                    }
                }

                span {
                    @include font-size(12);
                    margin: 18px 0 0;

                    &:before {
                        margin-right: 5px;
                    }

                    &:after {
                        margin-left: 5px;
                    }
                }

                .icons, h4 {
                    display: none;
                }
            }
        }
    }

    .gallery-about {
        margin: 0 0 -12px;

        .grid {
            .item {
                &:nth-child(9n+1), &:nth-child(9n+2), &:nth-child(9n+4), &:nth-child(9n+7), &:nth-child(9n+8) {
                    float: left;
                    margin-left: 0;
                }

                &:nth-child(9n+3), &:nth-child(9n+5), &:nth-child(9n+6), &:nth-child(9n) {
                    float: right;
                }

                &:nth-child(9n) {
                    a {
                        padding: calc(50% - 25px);

                        span {
                            background-size: 14px 14px;
                            height: 50px;
                            width: 50px;
                        }
                    }
                }
            }
        }
    }

    .contact-block {
        padding: 26px 0 40px;

        &.newsletter {
            padding: 33px 0 30px;

            p {
                text-align: left;
            }
        }

        h3 {
            @include font-size(50);
            line-height: 1.4; // 70px
            margin: 0 0 16px;
        }

        p {
            @include font-size(24);
            line-height: 1.33; // 32px
            margin: 0 auto 15px;
            max-width: 600px;
        }

        form {
            > input[type="email"] {
                @include font-size(14);
                line-height: 1.5; // 21px
                max-width: 100%;
                padding: 15px 12px;
            }

            label {
                @include font-size(12);
                line-height: 1.2; // 14px
                margin: 16px 0 14px;
                text-align: left;
            }
        }
    }

    .cookies {
        padding: 26px 0 16px;

        h4 {
            @include font-size(13);
            line-height: 1.54; // 20px
            margin: 0 0 5px;
            text-align: left;
        }

        p {
            @include font-size(13);
            line-height: 1.54; // 20px
            margin: 0 auto 11px;
            max-width: 100%;
            text-align: left;
        }
    }

    .btn {
        border-radius: 22px;
        @include font-size(14);
        line-height: 1.71; // 24px
        padding: 10px 0;
        width: 100%;
    }

    .flash-wrapper {
        padding-top: 10vh;

        .alert {
            max-width: 100%;
            padding: 23px 16px 0;

            h1 {
                @include font-size(24);
                font-weight: 400;
                line-height: 1.33; // 32px
                padding: 68px 0 0;

                &:before {
                    height: 44px;
                    left: calc(50% - 22px);
                    width: 45px;
                }
            }

            p {
                @include font-size(22);
                line-height: 1.36; // 30px
                margin: 0 0 22px;
            }

            &.alert-danger {
                max-width: 640px;

                a {
                    margin: 0 15px;
                    width: 115px;
                }
            }
        }
    }

}
