 @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Lora:400i');

// Variables
@import "variables";

// Mixins
@import "mixins";

// App SASS
@import "1920";
@import "1024";
@import "768";
@import "320";
