@media screen and (max-width: 992px) {
    .container {
        padding: 0;
        width: 720px;
    }

    .grid {
        margin: 0 auto;
        max-width: 720px;

        .item {
            margin-bottom: 18px;
            margin-left: 2.5%;

            &.w1 {
                width: 23.11%;
            }

            &.w3 {
                width: 74.3%;
            }

            &.h1 {
                height: 167px;
            }

            &.h2 {
                height: 352px;
            }
        }
    }

    .smileIcon {
        height: 25px;
        width: 88px;

        @for $i from 10 through 70 {
            &.size#{$i} {
                height: 25px * $i / 10;
                width: 88px * $i / 10;
            }
        }

        &.size40 {
            display: none;
        }
    }

    .header-wrapper {
        height: 89px;
        padding: 30px 0 0;

        .logo {
            margin: 5px 0 0;

            svg {
                height: 27px;
                width: 95px;
            }
        }

        .menu {
            margin: 9px 0 0 50px;

            > ul {
                li {
                    margin: 0 14px;

                    a {
                        @include font-size(13);
                        line-height: 1.7; // 22px
                    }
                }
            }
        }

        .megamenu-wrapper {
            bottom: calc(100% - 89px);
            top: 89px;

            .megamenu {
                > ul {
                    margin: 7vh 0 0;

                    > li {
                        @include font-size(37);
                        line-height: 1.22; // 45px
                        width: 50%;

                        &:nth-child(2n+1) {
                            margin-right: 0;
                            padding-left: 50px;
                            width: 50%;
                        }

                        &:nth-child(2n) {
                            margin-right: 0;
                            padding-left: 80px;
                            width: 50%;
                        }

                        > ul {
                            margin: 11px 0 83px;

                            > li {
                                @include font-size(15);
                                line-height: 2; // 30px
                            }
                        }
                    }
                }

                .close {
                    bottom: 60px;
                    @include font-size(16);
                    left: calc(50% - 26px);
                    line-height: 1.78; // 28px
                }
            }
        }

        .appointment-button {
            border-radius: 22px;
            @include font-size(13);
            line-height: 1.7; // 22px
            padding: 9px 25px;
        }
    }

    .offset {
        height: 89px;
    }

    .footer-wrapper {
        @include clearfix;
        padding: 20px 0 15px;

        .left {
            width: 100%;

            .logo {
                margin-bottom: 0;

                svg {
                    height: 27px;
                    width: 95px;
                }
            }

            p {
                display: none;
                @include font-size(14);
                line-height: 1.79; // 25px

                &.email {
                    display: inline-block;
                    float: right;
                }
            }
        }

        nav {
            display: none;
        }
    }

    .legal {
        @include font-size(12);
        line-height: 2; // 24px
        padding: 22px 0;
    }

    .services {
        padding: 225px 0 0;

        &.bgYellow {
            padding-bottom: 246px;
            padding-top: 190px;
        }

        .header {
            margin-bottom: 370px;

            h1 {
                @include font-size(59);
                line-height: 1.14; // 67px
                margin: 0 0 30px;

                &:before {
                    margin-right: 10px;
                }

                &:after {
                    margin-left: 10px;
                }
            }

            p {
                @include font-size(36);
                line-height: 1.28; // 46px
                max-width: 600px;
            }

            .icons {
                height: 30px;
                margin: 38px auto 0;
                width: 97px;
            }
        }

        .service {
            margin-top: 190px;

            .slider {
                li {
                    width: 720px;
                }
            }

            .bullets {
                margin: 34px auto 0;
                width: 200px;

                > a {
                    background-size: 17px 17px;
                    height: 35px;
                    margin-right: 15px;
                    width: 35px;

                    &.next {
                        margin-left: 15px;
                        margin-right: 0;
                    }
                }

                div {
                    padding: 10px 0;

                    a {
                        height: 15px;
                        margin: 0 5px;
                        width: 15px;
                    }
                }
            }

            .image {
                height: 201px;
                width: 258px;
            }

            h2 {
                @include font-size(37);
                line-height: 1.22; // 45px
                margin: 20px 0 9px;

                &:before {
                    margin-right: 19px;
                }

                &:after {
                    margin-left: 19px;
                }
            }

            p {
                @include font-size(18);
                line-height: 1.67; // 30px
                max-width: 540px;
            }

            .teeth {
                margin: 26px auto 0;
                width: 60px;

                span {
                    height: 11px;
                    margin: 0 5px;
                    width: 9px;

                    &.t2 {
                        height: 14px;
                        margin-top: -2px;
                        width: 12px;
                    }
                }
            }
        }
    }

    .team {
        margin-bottom: -22px;
        padding: 74px 0 0;

        .grid {
            .item {
                &.w3 {
                    .overlay {
                        padding: 24px 25px;

                        h3 {
                            @include font-size(29);
                            line-height: 1.21; // 35px
                        }

                        h4 {
                            @include font-size(11);
                            line-height: 1.67; // 18px
                            margin: 2px 0 12px;
                        }

                        p {
                            @include font-size(22);
                            line-height: 1.41; // 31px
                        }
                    }
                }

                &:first-child, &:nth-child(3), &:nth-child(4), &:nth-child(8), &:nth-child(11), &:nth-child(13) {
                    margin-left: 0;
                }

                &:nth-child(2) {
                    float: right;
                }

                &.title {
                    padding: 43px 14px 0;

                    svg {
                        height: 31px;
                        width: 32px;
                    }

                    h1, h2 {
                        @include font-size(29);
                        line-height: 1.28; // 37px
                        margin: 2px 0 0;
                    }
                }

                .toggle {
                    background-size: 13px 15px;
                    bottom: 6px;
                    height: 32px;
                    right: 6px;
                    width: 32px;
                }

                .overlay {
                    padding: 19px 13px;

                    h3 {
                        @include font-size(13);
                        line-height: 1.46; // 19px
                    }

                    p {
                        @include font-size(11);
                        line-height: 1.82; // 20px
                    }

                    .more {
                        background-size: 9px 9px;
                        bottom: 6px;
                        height: 32px;
                        right: 6px;
                        width: 32px;
                    }
                }
            }
        }
    }

    .doctor {
        > .image {
            background-size: 896px auto;
            height: 600px;
            position: relative !important;
            right: 0 !important;
            top: 0 !important;
        }

        .info {
            padding-left: 20px;
            width: 100%;

            h1 {
                @include font-size(37);
                line-height: 1.22; // 45px
                margin: 41px 0 0;
            }

            h2 {
                @include font-size(14);
                line-height: 1.67; // 23px
                margin: 3px 0 20px;
            }

            h3 {
                @include font-size(29);
                line-height: 1.38; // 40px
                margin: 0 0 20px;

                &:before {
                    margin-left: -20px;
                    margin-right: 10px;
                }
            }

            p {
                @include font-size(16);
                line-height: 1.875; // 30px
                margin: 0 0 15px;
            }

            .list-services {
                .service {
                    margin: 19px 40px 2px 0;
                    width: 134px;

                    &:nth-child(2n+1) {
                        clear: none;
                    }

                    &:nth-child(4n+1) {
                        clear: both;
                    }

                    .image {
                        height: 134px;
                        width: 134px;

                        &.diagnostico {
                            padding: 15px 0 0 30px;

                            svg {
                                height: 87px;
                                width: 68px;
                            }
                        }

                        &.tecnologia {
                            padding: 25px 0 0 19px;

                            svg {
                                height: 67px;
                                width: 87px;
                            }
                        }

                        &.ortodoncia {
                            padding: 52px 0 0 29px;

                            svg {
                                height: 26px;
                                width: 69px;
                            }
                        }

                        &.odontologia {
                            padding: 23px 0 0 32px;

                            svg {
                                height: 78px;
                                width: 60px;
                            }
                        }

                        &.atm {
                            padding: 32px 0 0 22px;

                            svg {
                                height: 62px;
                                width: 77px;
                            }
                        }

                        &.implantologia {
                            padding: 21px 0 0 37px;

                            svg {
                                height: 88px;
                                width: 55px;
                            }
                        }

                        &.endodoncia {
                            padding: 20px 0 0 32px;

                            svg {
                                height: 89px;
                                width: 66px;
                            }
                        }
                    }

                    h4 {
                        @include font-size(16);
                        margin: 14px 0 0;
                    }
                }
            }
        }
    }

    .gallery-doctor {
        margin-bottom: -5px;
        padding: 60px 0 0;

        .container {
            width: 100%;
        }

        .grid {
            .item {
                margin-bottom: 5px;
                margin-left: 0.69%;

                &.w1 {
                    width: 24.54%;
                }

                &.w3 {
                    width: 74.77%;
                }

                &.h1 {
                    height: 177px;
                }

                &.h2 {
                    height: 359px;
                }

                &:nth-child(2), &:nth-child(3) {
                    float: right;
                }
            }
        }
    }

    .gallery {
        margin-bottom: -18px;
        padding: 74px 0 0;

        .grid {
            .item {
                &:nth-child(9n+1), &:nth-child(9n+3), &:nth-child(9n+4), &:nth-child(9n+8) {
                    margin-left: 0;
                }

                &:nth-child(9n+2), &:nth-child(9n+7), &:nth-child(9n) {
                    float: right;
                }
            }
        }
    }

    .about {
        padding-top: 0;

        .content {
            max-width: 680px;

            .timeline {
                width: 25%;

                .icon1 {
                    height: 110px;
                    margin-left: 20px;
                    width: 122px;
                }

                .icon2 {
                    height: 100px;
                    margin-left: 16px;
                    width: 121px;
                }

                .icon3 {
                    height: 122px;
                    margin-left: 26px;
                    width: 126px;
                }

                .icon4 {
                    height: 162px;
                    margin-left: 0;
                    padding: 16px 32px;
                    width: 162px;

                    svg {
                        height: 113px;
                        width: 92px;
                    }
                }

                .line {
                    border-radius: 2px;
                    margin-left: 80px;
                    width: 3px;
                }

                .line1 {
                    height: 395px;
                    margin-bottom: 41px;
                    margin-top: 29px;
                }

                .line2 {
                    height: 419px;
                    margin-bottom: 39px;
                    margin-top: 31px;
                }

                .line3 {
                    height: 404px;
                    margin-bottom: 41px;
                    margin-top: 38px;
                }

                .line4 {
                    height: 421px;
                    margin-bottom: 0;
                    margin-top: 41px;
                }
            }

            .info {
                width: 71.43%;

                p {
                    @include font-size(29);
                    line-height: 1.45; // 42px
                    padding-right: 30px;

                    &.info1 {
                        margin-top: 9px;
                    }

                    &.info2 {
                        margin-top: 271px;
                    }

                    &.info3 {
                        margin-top: 341px;
                    }

                    &.info4 {
                        margin-top: 460px;
                    }
                }
            }
        }
    }

    .contact {
        .content {
            max-width: 100%;

            .timeline {
                padding-left: 0;
                width: 16.6%;

                .icon {
                    height: 88px;
                    margin-bottom: 28px;
                    width: 101px;
                }

                .line {
                    border-radius: 2px;
                    height: 182px;
                    margin-left: 49px;
                    width: 3px;
                }
            }

            .info {
                width: 83.4%;

                h3 {
                    @include font-size(14);
                    line-height: 1.71; // 24px
                    margin: 21px 0 0;
                }

                h1, h2 {
                    @include font-size(36);
                    line-height: 1.25; // 45px
                    margin: 0 0 6px;
                }

                p {
                    @include font-size(18);
                    line-height: 1.67; // 30px
                }

                .columns {
                    margin-right: 30px;
                    margin-top: 24px;
                    padding-bottom: 32px;

                    .col {
                        &.col1 {
                            width: 92px;
                        }

                        &.col2 {
                            margin-left: 50px;
                            width: 220px;
                        }

                        &.col3 {
                            margin-left: 50px;
                        }

                        h4 {
                            @include font-size(16);
                            line-height: 1.5; // 24px
                        }

                        p {
                            @include font-size(16);
                            line-height: 1.5; // 24px
                        }
                    }
                }

                h2 {
                    margin-top: 29px;
                }
            }
        }

        #map_canvas {
            height: 500px;
            margin: 15px auto 0;
            width: 100%;
        }
    }

    .appointment {
        padding: 34px 0 46px;

        h1 {
            @include font-size(36);
            line-height: 1.39; // 50px
            margin: 0 0 46px;
        }

        .field {
            border-radius: 0;
            margin: 0 auto;
            opacity: 1 !important;
            padding: 39px 40px 13px;
            width: 100%;

            &:first-child {
                border-top-right-radius: 22px;
                border-top-left-radius: 22px;
            }

            &:last-child {
                border-bottom-right-radius: 22px;
                border-bottom-left-radius: 22px;
                padding-bottom: 70px;
            }

            .next {
                background-size: 20px 12px;
                bottom: -35px;
                height: 70px;
                left: calc(50% - 35px);
                width: 70px;
            }

            > label {
                @include font-size(33);
                line-height: 1.33; // 44px
                margin: 0 0 18px;
            }

            input, select, textarea {
                @include font-size(14);
                line-height: 1.5; // 21px
                padding: 15px 12px;
            }

            textarea {
                height: 51px;
            }

            .half {
                width: 48%;

                &:last-child {
                    margin-left: 4%;
                }

                label {
                    @include font-size(16);
                    line-height: 1.3; // 21px
                    margin: 0 0 12px;
                }
            }
        }
    }

    .appointment-footer {
        padding: 57px 0 59px;

        p {
            @include font-size(36);
            line-height: 1.39; // 50px
            max-width: 600px;
        }

        label {
            @include font-size(14);
            line-height: 1.5; // 21px
            margin: 23px 0 38px;
        }
    }

    .static {
        padding-top: 40px;

        .content {
            max-width: 686px;

            .timeline {
                width: 15%;

                .icon {
                    height: 104px;
                    margin-bottom: 11px;
                    width: 89px;
                }

                .line {
                    border-radius: 2px;
                    height: 200px;
                    margin-left: 30px;
                    width: 3px;
                }
            }

            .info {
                padding-right: 20px;
                width: 82%;

                h2 {
                    @include font-size(14);
                    font-weight: 400;
                    line-height: 1.71; // 24px
                    margin: 18px 0 2px;
                }

                h1 {
                    @include font-size(37);
                    line-height: 1.22; // 45px
                    margin: 0 0 13px;
                }

                p, ul {
                    @include font-size(14);
                    line-height: 1.71; // 24px
                    margin: 0 0 25px;
                }

                h3 {
                    @include font-size(14);
                    line-height: 1.71; // 24px
                }

                table {
                    margin: 20px 0;
                }
            }
        }
    }

    .home {
        .quote-block {
            padding-top: 115px;
        }
    }

    .home-placeholder {
        height: calc(100vh - 89px);
    }

    .hero {
        padding: 30vh 0 0;
        top: 89px;

        .icons {
            height: 54px;

            svg {
                margin: 0 10px;
            }
        }

        h1 {
            @include font-size(33);
            margin: 27px 0 0;
        }

        .down {
            background-size: 20px 12px;
            bottom: 50px;
            height: 44px;
            left: calc(50% - 22px);
            width: 44px;
        }
    }

    @keyframes downButton {
        0% {
            bottom: 60px;
        }

        10% {
            bottom: 50px;
        }

        20% {
            bottom: 60px;
        }

        30% {
            bottom: 50px;
        }

        90% {
            bottom: 50px;
        }

        100% {
            bottom: 60px;
        }
    }

    .home-diagnosis {
        padding: 0 0 170px;

        .icons {
            .icon {
                height: 150px;
                margin: 0 30px;
                width: 150px;

                &:first-child {
                    margin-left: 30px;
                    padding: 37px 0 0 43px;

                    svg {
                        height: 71px;
                        width: 58px;
                    }
                }

                &:nth-child(2) {
                    padding: 31px 0 0 18px;

                    svg {
                        height: 77px;
                        width: 96px;
                    }
                }

                &:last-child {
                    padding: 38px 0 0 24px;

                    svg {
                        height: 67px;
                        width: 96px;
                    }
                }
            }
        }

        h2 {
            @include font-size(38);
            line-height: 1.32; // 50px
            margin: 79px auto 5px;
            max-width: 600px;
        }

        p {
            @include font-size(27);
            line-height: 1.55; // 42px
            margin: 0 auto 47px;
            max-width: 600px;
        }

        .btn {
            margin: 0 0 0 60px;
        }
    }

    .home-gallery {
        padding-bottom: 58px;

        .grid {
            .item {
                &:nth-child(9n+1), &:nth-child(9n+3), &:nth-child(9n+4), &:nth-child(9n+8) {
                    margin-left: 0;
                }

                &:nth-child(9n+2), &:nth-child(9n+7), &:nth-child(9n) {
                    float: right;
                }
            }
        }

        .more {
            background-size: 14px 14px;
            height: 50px;
            margin: 40px auto 0;
            width: 50px;
        }
    }

    .home-services {
        padding: 160px 0 160px;

        h2 {
            @include font-size(36);
            line-height: 1.28; // 46px;
            margin: 0 0 32px;

            &:before {
                margin-right: 10px;
            }

            &:after {
                margin-left: 10px;
            }
        }

        .list-services {
            max-width: 664px;

            .service {
                margin: 0 0 59px 64px;

                &:nth-child(4n+1) {
                    margin-left: 0;
                }

                &:first-child {
                    .image {
                        padding: 13px 0 0 27px;

                        svg {
                            height: 77px;
                            width: 60px;
                        }
                    }
                }

                &:nth-child(2) {
                    .image {
                        padding: 22px 0 0 16px;

                        svg {
                            height: 59px;
                            width: 77px;
                        }
                    }
                }

                &:nth-child(3) {
                    .image {
                        padding: 46px 0 0 25px;

                        svg {
                            height: 23px;
                            width: 61px;
                        }
                    }
                }

                &:nth-child(4) {
                    .image {
                        padding: 20px 0 0 27px;

                        svg {
                            height: 69px;
                            width: 53px;
                        }
                    }
                }

                &:nth-child(5) {
                    .image {
                        padding: 18px 0 0 28px;

                        svg {
                            height: 70px;
                            width: 51px;
                        }
                    }
                }

                &:nth-child(6) {
                    .image {
                        padding: 17px 0 0 20px;

                        svg {
                            height: 71px;
                            width: 71px;
                        }
                    }
                }

                &:nth-child(7) {
                    .image {
                        padding: 18px 0 0 32px;

                        svg {
                            height: 76px;
                            width: 47px;
                        }
                    }
                }

                &:last-child {
                    .image {
                        padding: 19px 0 0 26px;

                        svg {
                            height: 69px;
                            width: 56px;
                        }
                    }
                }

                .image {
                    height: 117px;
                    width: 117px;
                }

                h3 {
                    @include font-size(14);
                    line-height: 1.5; // 21px
                    margin: 8px 0 0;
                }
            }
        }
    }

    .page404 {
        .container {
            margin-top: 108px;
            padding-bottom: 110px;
            padding-top: 10px;
        }

        .icon {
            height: 225px;
            margin: 10px auto 0;
            width: 293px;
        }

        h1 {
            @include font-size(424);
            line-height: 0.68;
        }

        p {
            @include font-size(46);
            line-height: 1.35; // 62px
            margin: 20px auto 20px;
            max-width: 600px;
        }
    }

    .gallery-wrapper {
        .images-container {
            margin-top: 40px;
        }

        .images {
            img {
                width: 720px;
            }
        }

        nav {
            margin: 20px 0;

            a {
                height: 50px;
                margin-left: 20px;
                width: 50px;

                &.prev, &.next {
                    background-size: 16px 16px;
                }

                &.close {
                    background-size: 12px 12px;
                }
            }
        }
    }

    .quote-block {
        .container {
            padding-bottom: 26.3vh;
            padding-top: 18vh;

            .btn {
                background-size: 13px 13px;
                height: 47px;
                margin: -24px auto 0;
                width: 47px;
            }
        }

        p {
            @include font-size(30);
            line-height: 1.47; // 44px
            max-width: 680px;
            padding: 112px 40px 83px;

            &:before {
                background-size: 69px 68px;
                height: 68px;
                left: calc(50% - 65px);
                padding: 0 30px;
                top: -34px;
                width: 129px;
            }
        }

        &.quote2 {
            .container {
                padding-bottom: 19vh;
                padding-top: 19vh;
            }

            p {
                border: none;
                @include font-size(55);
                line-height: 1.27; // 70px
                padding: 74px 0 0;

                &:before {
                    background-color: transparent;
                    left: calc(50% - 47px);
                    width: 94px;
                }
            }

            h5 {
                @include font-size(20);
                line-height: 1.8; // 36px
                margin: 27px 0 90px;

                &:before {
                    margin-right: 5px;
                }

                &:after {
                    margin-left: 5px;
                }
            }

            .down {
                display: block;
            }
        }

        &.quote-about {
            p {
                margin: 0 auto;
                max-width: 720px;
                padding: 70px 60px 45px;
            }
        }

        &.drquote {
            .container {
                padding: 132px 0 56px;

                p {
                    border: none;
                    @include font-size(55);
                    line-height: 1.28; // 70px
                    padding: 120px 0 0;

                    &:before {
                        background-color: transparent;
                        left: calc(50% - 47px);
                        width: 94px;
                    }
                }

                span {
                    margin: 33px 0 75px;

                    &:before {
                        margin-right: 5px;
                    }

                    &:after {
                        margin-left: 5px;
                    }
                }

                .icons {
                    height: 35px;

                    svg {
                        margin: 0 7px;
                    }
                }

                h4 {
                    @include font-size(19);
                    margin: 27px 0 0;
                }
            }
        }
    }

    .gallery-about {
        margin: 0 0 -22px;

        .grid {
            .item {
                &:nth-child(9n+1), &:nth-child(9n+4), &:nth-child(9n) {
                    margin-left: 0;
                }

                &:nth-child(9n+2), &:nth-child(9n+3), &:nth-child(9n+8) {
                    float: right;
                }

                &:nth-child(9n) {
                    a {
                        padding: calc(50% - 25px);

                        span {
                            background-size: 14px 14px;
                            height: 50px;
                            width: 50px;
                        }
                    }
                }
            }
        }
    }

    .contact-block {
        padding: 130px 0 160px;

        &.newsletter {
            padding: 89px 0 111px;
        }

        h3 {
            @include font-size(54);
            line-height: 1.3; // 70px
            margin: 0 0 16px;
        }

        p {
            @include font-size(29);
            line-height: 1.38; // 40px
            margin: 0 auto 27px;
            max-width: 600px;
        }

        form {
            > input[type="email"] {
                @include font-size(14);
                line-height: 1.5; // 21px
                max-width: 600px;
                padding: 15px 12px;
            }

            label {
                @include font-size(14);
                line-height: 1.5; // 21px
                margin: 23px 0 38px;
            }
        }
    }

    .cookies {
        padding: 26px 30px 16px;

        h4 {
            @include font-size(13);
            line-height: 1.54; // 20px
            margin: 0 0 5px;
        }

        p {
            @include font-size(13);
            line-height: 1.54; // 20px
            margin: 0 auto 11px;
            max-width: 600px;
        }
    }

    .btn {
        border-radius: 22px;
        @include font-size(14);
        line-height: 1.71; // 24px
        padding: 13px 0;
        width: 180px;
    }

    .flash-wrapper {
        padding-top: 18vh;

        .alert {
            max-width: 600px;
            padding: 53px 0 0;

            h1 {
                @include font-size(46);
                line-height: 1.35; // 62px
                padding: 122px 0 0;

                &:before {
                    height: 92px;
                    left: calc(50% - 47px);
                    width: 94px;
                }
            }

            p {
                @include font-size(26);
                line-height: 1.38; // 36px
                margin: 22px 0;
            }

            &.alert-danger {
                max-width: 640px;

                a {
                    margin: 15px 15px 0;
                    width: 115px;
                }
            }
        }
    }

}
