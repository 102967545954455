*,
*:before,
*:after {
    @include box-sizing(border-box);
}

html, body {
    color: $colorGrey;
    font-family: $fontOpenSans;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
}

a {
    outline: none;
}

.container {
    margin: 0 auto;
    padding: 0 21px;
    width: 1200px;
}

.bgYellow {
    background-color: $colorYellow;
}

.bgWhite {
    background-color: #fff;
}

.bgBlue {
    background-color: $colorBlue;
}

.grid {
    @include clearfix;
    position: relative;
    z-index: 1;

    &.grid-mobile {
        display: none;
    }

    .item {
        display: inline-block;
        float: left;
        margin-bottom: 30px;
        margin-left: 2.5%;
        overflow: hidden;
        position: relative;
        z-index: 1;

        &.w1 {
            width: 23.06%;
        }

        &.w3 {
            width: 74.18%;
        }

        &.h1 {
            height: 267px;
        }

        &.h2 {
            height: 564px;
        }

        img {
            display: block;
            height: 100%;
            width: 100%;
        }
    }
}

.smileIcon {
    display: block;
    height: 25px;
    position: absolute;
    width: 88px;
    transform-origin: 0 0;
    z-index: 0;

    &.yellow {
        svg {
            path{
                fill: $colorYellow;
            }
        }
    }

    &.grey {
        svg {
            path{
                fill: $colorGrey;
            }
        }
    }

    &.blue {
        svg {
            path{
                fill: $colorBlue;
            }
        }
    }

    &.white {
        svg {
            path{
                fill: #fff;
            }
        }
    }

    svg {
        height: 100%;
        transform-origin: 50% 50%;
        width: 100%;
    }

    @for $i from 1 through 10 {
        &.opacity#{$i}0 {
            @include opacity($i / 10);
        }
    }

    @for $i from 1 through 360 {
        &.rotation#{$i} {
            svg {
                @include transformRotate(#{$i}deg);
            }
        }
    }

    @for $i from 10 through 70 {
        &.size#{$i} {
            height: 25px * $i / 10;
            width: 88px * $i / 10;
        }
    }

    &.size62 {
        display: none;
    }
}

.header-wrapper {
    background-color: #fff;
    height: 100px;
    left: 0;
    padding: 30px 0 0;
    position: fixed;
    right: 0;
    top: 0;
    @include transition(background-color 0.4s linear)
    z-index: 5;

    &.yellow {
        background-color: $colorYellow;

        .logo svg .smile {
            fill: #fff;
        }

        .menu {
            > ul {
                li {
                    a {
                        color: #fff;

                        &:hover {
                            color: #fff;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .appointment-button {
            border: solid 1px #fff;
            color: #fff;

            &:hover {
                background-color: #fff;
                color: $colorYellow;
            }
        }

        .megamenu-wrapper {
            background-color: $colorYellow;

            .megamenu {
                > ul {
                    > li {
                        color: #fff;

                        > ul {
                            > li {
                                color: #fff;

                                a {
                                    color: #fff;

                                    &:hover {
                                        color: #fff;
                                        text-decoration: underline;
                                    }

                                    &.yellow {
                                        color: #fff;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }

                .close {
                    color: #fff;
                }
            }
        }
    }

    &.blue {
        background-color: $colorBlue;

        .logo svg .smile {
            fill: #fff;
        }
    }

    .logo {
        display: inline-block;
        float: left;
        margin: 7px 0 0;

        svg {
            height: 30px;
            width: 105px;

            .smile {
                fill: $colorYellow;
            }
        }
    }

    .burger {
        display: none;
    }

    .menu {
        display: inline-block;
        float: left;
        margin: 11px 0 0 214px;

        > ul {
            @include clearfix;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                float: left;
                margin: 0 26px;

                a {
                    color: $colorGrey;
                    @include font-size(14);
                    line-height: 1.7; // 24px
                    text-decoration: none;
                    text-transform: uppercase;

                    &:hover {
                        color: $colorYellow;
                    }
                }
            }
        }
    }

    .megamenu-wrapper {
        background-color: #fff;
        bottom: calc(100% - 100px);
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 100px;
        @include transition(opacity 0.7s);
        z-index: -1;

        &.active {
            bottom: 0;
            opacity: 1;
            z-index: 5;
        }

        .megamenu {
            > ul {
                list-style: none;
                margin: 7vh 0 0;
                padding: 0;

                > li {
                    display: inline-block;
                    float: left;
                    @include font-size(46);
                    font-weight: 300;
                    line-height: 1.22; // 56px
                    width: 33.33%;

                    &:nth-child(3n+1) {
                        margin-right: 13.8%;
                        width: 21.6%;
                    }

                    &:nth-child(3n+2) {
                        margin-right: 14.2%;
                        width: 23.45%;
                    }

                    &:nth-child(3n) {
                        width: 26.95%;
                    }

                    > ul {
                        list-style: none;
                        margin: 11px 0 83px;
                        padding: 0;

                        > li {
                            display: block;
                            @include font-size(18);
                            font-weight: 400;
                            line-height: 1.78; // 32px

                            a {
                                color: $colorGrey;
                                text-decoration: none;

                                &:hover {
                                    color: $colorYellow;
                                    text-decoration: underline;
                                }

                                &.yellow {
                                    color: $colorYellow;
                                    font-weight: 600;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            .close {
                bottom: 60px;
                color: $colorGrey;
                @include font-size(18);
                font-weight: 600;
                left: calc(50% - 26px);
                line-height: 1.78; // 32px
                position: absolute;
                text-decoration: underline;
                text-transform: uppercase;
            }
        }
    }

    .appointment-button {
        border: solid 1px $colorGrey;
        border-radius: 22px;
        color: $colorGrey;
        display: inline-block;
        float: right;
        @include font-size(14);
        line-height: 1.7; // 24px
        padding: 11px 30px;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            background-color: $colorYellow;
            border-color: $colorYellow;
            color: #fff;
        }
    }
}

.offset {
    height: 100px;
}

.footer-wrapper {
    background-color: $colorLightGrey;
    padding: 60px 0 74px;
    position: relative;
    z-index: 1;

    .left {
        display: inline-block;
        float: left;

        .logo {
            display: inline-block;
            margin-bottom: 28px;

            svg {
                height: 30px;
                width: 105px;

                path {
                    fill: #a7a6a6;
                }

                .smile {
                    fill: $colorYellow;
                }
            }
        }

        p {
            color: #d3d2d2;
            @include font-size(14);
            line-height: 1.86; // 26px
            margin: 0;

            a {
                color: #d3d2d2;
                text-decoration: none;
            }

            &.email {
                a {
                    color: $colorYellow;
                    text-decoration: underline;
                }
            }
        }
    }

    nav {
        @include clearfix;

        > ul {
            list-style: none;
            margin: 0;
            padding: 0;

            > li {
                color: #adacac;
                display: inline-block;
                float: left;
                @include font-size(14);
                line-height: 1.86; // 26px
                margin-left: 51px;
                text-transform: uppercase;

                ul {
                    list-style: none;
                    margin: 9px 0 0;
                    padding: 0;

                    li {
                        margin: 0 0 5px;
                        text-transform: none;

                        a {
                            color: #cacaca;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

.legal {
    background-color: #fff;
    color: #abaaaa;
    @include font-size(14);
    line-height: 1.86; // 26px
    padding: 22px 0;
    position: relative;
    z-index: 1;

    a {
        color: #abaaaa;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.services {
    padding: 125px 0 30px;

    .header {
        h1 {
            @include font-size(74);
            font-weight: 300;
            line-height: 1.14; // 84px
            margin: 0 0 30px;
            position: relative;
            text-align: center;
            z-index: 1;

            &:before {
                content: '-';
                display: inline-block;
                margin-right: 19px;
            }

            &:after {
                content: '-';
                display: inline-block;
                margin-left: 19px;
            }
        }

        p {
            @include font-size(46);
            font-weight: 300;
            line-height: 1.22; // 56px
            margin: 0 auto;
            max-width: 730px;
            position: relative;
            text-align: center;
            z-index: 1;
        }

        .icons {
            display: block;
            height: 37px;
            margin: 38px auto 0;
            position: relative;
            width: 121px;
            z-index: 1;

            svg {
                height: 100%;
                width: 100%;

                path {
                    fill: $colorBlue;
                }
            }
        }
    }

    .service {
        margin-top: 202px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        .slider {
            margin: 0;
            overflow: hidden;
            padding: 0;
            @include transition(all 0.8s ease-in-out);
            width: 9999px;

            li {
                display: inline-block;
                float: left;
                width: 1158px;
            }
        }

        .bullets {
            list-style: none;
            margin: 34px auto 0;
            width: 200px;

            > a {
                background: url(../img/slider-arrow.svg) center center no-repeat;
                background-size: 17px 17px;
                border: solid 1px #fff;
                border-radius: 50%;
                display: inline-block;
                float: left;
                height: 35px;
                margin-right: 15px;
                text-indent: -9999px;
                width: 35px;

                &.next {
                    margin-left: 15px;
                    margin-right: 0;
                    @include transformRotate(180deg);
                    text-indent: 9999px;
                }
            }

            div {
                display: inline-block;
                float: left;
                padding: 10px 0;

                a {
                    border: solid 1px #fff;
                    border-radius: 50%;
                    display: inline-block;
                    float: left;
                    height: 15px;
                    margin: 0 5px;
                    text-indent: -9999px;
                    width: 15px;

                    &.active {
                        background-color: #fff;
                    }
                }
            }
        }

        .image {
            height: 251px;
            margin: 0 auto;
            width: 322px;

            svg {
                height: 100%;
                width: 100%;

                path:not(.fill), line:not(.fill), rect:not(.fill) {
                    stroke: $colorYellow;
                }

                .fill {
                    fill: $colorYellow;
                }
            }
        }

        h2 {
            @include font-size(46);
            font-weight: 300;
            line-height: 1.22; // 56px
            margin: 20px 0 9px;
            text-align: center;

            &:before {
                content: '-';
                display: inline-block;
                margin-right: 19px;
            }

            &:after {
                content: '-';
                display: inline-block;
                margin-left: 19px;
            }
        }

        p {
            @include font-size(20);
            line-height: 1.8; // 36px
            margin: 0 auto;
            max-width: 660px;
            text-align: center;
        }

        .teeth {
            margin: 26px auto 0;
            width: 67px;

            span {
                display: inline-block;
                height: 14px;
                margin: 0 5px;
                width: 11px;

                &.t2 {
                    height: 18px;
                    margin-top: -2px;
                    width: 15px;
                }

                svg {
                    height: 100%;
                    width: 100%;

                    path {
                        fill: #9e9e9e;
                    }
                }
            }
        }
    }

    &.bgYellow {
        padding-bottom: 246px;

        .header {
            h1 {
                color: #fff;
            }

            p {
                color: #fff;
            }

            .icons {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }

        .service {
            .image {
                svg {
                    path:not(.fill), line:not(.fill), rect:not(.fill) {
                        stroke: #fff;
                    }

                    .fill {
                        fill: #fff;
                    }
                }
            }

            h2 {
                color: #fff;
            }

            p {
                color: #fff;
            }
        }
    }
}

.team {
    @include clearfix;
    margin-bottom: -30px;
    padding: 74px 0 0;

    .grid {
        .item {
            &.w3 {
                .overlay {
                    padding: 38px 40px;

                    h3 {
                        @include font-size(46);
                        font-weight: 300;
                        line-height: 1.22; // 56px
                    }

                    h4 {
                        @include font-size(18);
                        font-weight: 600;
                        line-height: 1.67; // 30px
                        margin: 3px 0 21px;
                    }

                    p {
                        @include font-size(36);
                        line-height: 1.39; // 50px
                    }
                }
            }

            &:first-child, &:nth-child(3), &:nth-child(4), &:nth-child(8), &:nth-child(11), &:nth-child(13) {
                margin-left: 0;
            }

            &:nth-child(2) {
                float: right;
            }

            &.title {
                border: solid 1px $colorBlue;
                padding: 73px 22px 0;

                svg {
                    height: 50px;
                    width: 51px;
                }

                h1, h2 {
                    @include font-size(46);
                    font-weight: 300;
                    line-height: 1.22; // 56px
                    margin: 2px 0 0;
                }
            }

            .toggle {
                background: url(../img/ico-view.svg) center center no-repeat $colorYellow;
                background-size: 20px 24px;
                border-radius: 50%;
                bottom: 10px;
                display: block;
                height: 50px;
                position: absolute;
                right: 10px;
                text-indent: -9999px;
                width: 50px;
            }

            &:hover .overlay {
                opacity: 1;
            }

            .overlay {
                background-color: #fff;
                border: solid 1px $colorBlue;
                bottom: 0;
                color: $colorGrey;
                display: block;
                left: 0;
                opacity: 0;
                padding: 28px 20px;
                position: absolute;
                right: 0;
                text-decoration: none;
                top: 0;

                h3 {
                    @include font-size(20);
                    font-weight: 400;
                    line-height: 1.5; // 30px
                    margin: 0;
                }

                p {
                    @include font-size(18);
                    font-weight: 300;
                    line-height: 1.78; // 32px
                    margin: 0;
                }

                .more {
                    background: url(../img/ico-more-white.svg) center center no-repeat $colorYellow;
                    background-size: 14px 14px;
                    border-radius: 50%;
                    bottom: 10px;
                    display: block;
                    height: 50px;
                    position: absolute;
                    right: 10px;
                    text-indent: -9999px;
                    width: 50px;

                    &:hover {
                        background-color: $colorBlue;
                    }
                }
            }
        }
    }
}

.doctor {
    @include clearfix;
    position: relative;

    .container {
        position: relative;
        z-index: 1;
    }

    > .image {
        background-position: center top;
        background-size: 957px auto;
        height: 641px;
        left: 0;
        overflow: hidden;
        position: fixed;
        right: 50%;
        text-indent: -9999px;
        top: 0;
        z-index: 1;
    }

    .info {
        display: inline-block;
        float: right;
        padding-left: 60px;
        width: 50%;

        h1 {
            @include font-size(46);
            font-weight: 300;
            line-height: 1.22; // 56px
            margin: 41px 0 0;
        }

        h2 {
            @include font-size(18);
            font-weight: 600;
            line-height: 1.67; // 30px
            margin: 3px 0 20px;
        }

        h3 {
            @include font-size(36);
            font-weight: 300;
            line-height: 1.39; // 50px
            margin: 0 0 20px;

            &:before {
                content: '-';
                display: inline-block;
                margin-left: -20px;
                margin-right: 10px;
            }
        }

        p {
            @include font-size(18);
            font-weight: 300;
            line-height: 1.78; // 32px
            margin: 0 0 15px;
        }

        .list-services {
            @include clearfix;

            .service {
                display: inline-block;
                float: left;
                margin: 37px 60px 20px 0;
                width: 166px;

                &:nth-child(2n+1) {
                    clear: both;
                }

                .image {
                    border: solid 4px $colorYellow;
                    border-radius: 50%;
                    height: 166px;
                    width: 166px;

                    &.diagnostico {
                        padding: 20px 0 0 38px;

                        svg {
                            height: 109px;
                            width: 85px;
                        }
                    }

                    &.tecnologia {
                        padding: 33px 0 0 24px;

                        svg {
                            height: 84px;
                            width: 109px;
                        }
                    }

                    &.ortodoncia {
                        padding: 67px 0 0 37px;

                        svg {
                            height: 33px;
                            width: 86px;
                        }
                    }

                    &.odontologia {
                        padding: 30px 0 0 41px;

                        svg {
                            height: 98px;
                            width: 75px;
                        }
                    }

                    &.atm {
                        padding: 40px 0 0 28px;

                        svg {
                            height: 77px;
                            width: 96px;
                        }
                    }

                    &.implantologia {
                        padding: 30px 0 0 50px;

                        svg {
                            height: 98px;
                            width: 61px;
                        }
                    }

                    &.endodoncia {
                        padding: 26px 0 0 42px;

                        svg {
                            height: 99px;
                            width: 73px;
                        }
                    }

                    svg {
                        path:not(.nofill), line, rect {
                            fill: $colorYellow;
                        }
                    }
                }

                h4 {
                    color: $colorYellow;
                    @include font-size(20);
                    line-height: 1;
                    margin: 14px 0 0;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.gallery-doctor {
    @include clearfix;
    margin-bottom: -10px;
    padding: 60px 0 0;

    .container {
        padding: 0;
        width: 1504px;
    }

    .grid {
        .item {
            margin-bottom: 10px;
            margin-left: 0.59%;

            &.w1 {
                width: 24.54%;
            }

            &.w3 {
                width: 74.87%;
            }

            &.h1 {
                height: 371px;
            }

            &.h2 {
                height: 752px;
            }

            &:first-child {
                margin-left: 0;
            }

            &:nth-child(2), &:nth-child(3) {
                float: right;
            }
        }
    }
}

.gallery {
    @include clearfix;
    margin-bottom: -30px;
    padding: 74px 0 0;

    .grid {
        .item {
            &:nth-child(9n+1), &:nth-child(9n+3), &:nth-child(9n+4), &:nth-child(9n+8) {
                margin-left: 0;
            }

            &:nth-child(9n+2), &:nth-child(9n+7), &:nth-child(9n) {
                float: right;
            }
        }
    }
}

.about {
    padding-top: 285px;

    .content {
        @include clearfix;
        margin: 0 auto;
        max-width: 840px;
        position: relative;
        z-index: 1;

        .timeline {
            display: inline-block;
            float: left;
            width: 25%;

            .icon {
                display: block;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }

            .icon1 {
                height: 137px;
                margin-left: 28px;
                width: 152px;
            }

            .icon2 {
                height: 125px;
                margin-left: 23px;
                width: 151px;
            }

            .icon3 {
                height: 152px;
                margin-left: 24px;
                width: 157px;
            }

            .icon4 {
                border: solid 4px $colorYellow;
                border-radius: 50%;
                height: 203px;
                margin-left: 3px;
                padding: 27px 40px;
                width: 203px;

                svg {
                    height: 142px;
                    width: 115px;
                }
            }

            .line {
                background-color: $colorYellow;
                border-radius: 2px;
                margin-left: 102px;
                width: 4px;
            }

            .line1 {
                height: 494px;
                margin-bottom: 51px;
                margin-top: 36px;
            }

            .line2 {
                height: 524px;
                margin-bottom: 49px;
                margin-top: 39px;
            }

            .line3 {
                height: 505px;
                margin-bottom: 51px;
                margin-top: 48px;
            }

            .line4 {
                height: 324px;
                margin-bottom: 0;
                margin-top: 50px;
            }
        }

        .info {
            display: inline-block;
            float: right;
            width: 71.43%;

            .icon {
                display: none;
            }

            p {
                @include font-size(36);
                font-weight: 300;
                line-height: 1.39; // 50px
                margin: 0;
                padding-right: 30px;

                strong {
                    font-weight: 400;
                }

                span {
                    color: $colorYellow;
                }

                a {
                    color: $colorYellow;
                    text-decoration: underline;
                }

                &.info1 {
                    margin-top: 9px;
                }

                &.info2 {
                    margin-top: 349px;
                }

                &.info3 {
                    margin-top: 447px;
                }

                &.info4 {
                    margin-top: 592px;
                }
            }
        }
    }
}

.contact {
    .content {
        @include clearfix;
        margin: 0 auto;
        max-width: 1040px;
        position: relative;
        z-index: 1;

        .timeline {
            display: inline-block;
            float: left;
            padding-left: 110px;
            width: 25%;

            .icon {
                display: block;
                height: 110px;
                margin-bottom: 35px;
                width: 126px;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }

            .line {
                background-color: $colorYellow;
                border-radius: 2px;
                height: 227px;
                margin-left: 62px;
                width: 4px;
            }
        }

        .info {
            display: inline-block;
            float: right;
            width: 71.43%;

            h3 {
                color: $colorYellow;
                @include font-size(18);
                font-weight: 400;
                line-height: 1.67; // 30px
                margin: 21px 0 0;
                text-transform: uppercase;
            }

            h1, h2 {
                @include font-size(46);
                font-weight: 300;
                line-height: 1.22; // 56px
                margin: 0 0 16px;
            }

            p {
                @include font-size(18);
                font-weight: 400;
                line-height: 1.67; // 30px
                margin: 0;
            }

            .columns {
                border-bottom: solid 1px $colorYellow;
                @include clearfix;
                margin-top: 29px;
                padding-bottom: 46px;

                .col {
                    display: inline-block;
                    float: left;

                    &.col1 {
                        width: 120px;
                    }

                    &.col2 {
                        margin-left: 80px;
                        width: 280px;
                    }

                    &.col3 {
                        margin-left: 80px;
                    }

                    h4 {
                        color: $colorYellow;
                        @include font-size(18);
                        font-weight: 400;
                        line-height: 1.67; // 30px
                        margin: 0;
                        text-transform: uppercase;
                    }

                    p {
                        @include font-size(18);
                        font-weight: 600;
                        line-height: 1.67; // 30px
                        margin: 0;

                        a {
                            color: $colorGrey;
                            text-decoration: none;

                            &.email {
                                color: $colorYellow;
                                text-decoration: underline;
                            }

                            &:hover {
                                color: $colorYellow;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            h2 {
                margin-top: 40px;
            }
        }
    }

    #map_canvas {
        height: 645px;
        margin-top: 15px;
        width: 100%;
    }
}

.appointment {
    padding: 69px 0 1px;

    .container {
        position: relative;
        z-index: 1;
    }

    h1 {
        color: #fff;
        @include font-size(46);
        font-weight: 300;
        line-height: 1.22; // 56px
        margin: 0 0 48px;
        text-align: center;
    }

    .field {
        background-color: #fff;
        border-radius: 22px;
        @include clearfix;
        margin: 0 auto 120px;
        @include opacity(0.5);
        padding: 54px 60px 83px;
        position: relative;
        @include transition(opacity 0.2s);
        width: 900px;

        &.active {
            @include opacity(1);
        }

        .next {
            background: url(../img/arrow.svg) center center no-repeat $colorBlue;
            background-size: 20px 12px;
            border-radius: 50%;
            bottom: -35px;
            display: block;
            height: 70px;
            left: calc(50% - 35px);
            overflow: hidden;
            position: absolute;
            text-indent: -9999px;
            @include transition(all 0.2s);
            width: 70px;
            z-index: 1;

            &:hover {
                @include transformRotate(360deg);
            }
        }

        > label {
            display: block;
            @include font-size(36);
            font-weight: 300;
            line-height: 1.28; // 46px
            margin: 0 0 21px;
            @include opacity(0.5);
        }

        input, select, textarea {
            background-color: #fff;
            border: solid 1px $colorYellow;
            border-radius: 4px;
            color: $colorGrey;
            font-family: $fontOpenSans;
            @include font-size(14);
            line-height: 1.5; // 21px
            padding: 15px 12px;
            width: 100%;
        }

        textarea {
            height: 51px;
        }

        .half {
            display: inline-block;
            float: left;
            width: 48%;

            &:last-child {
                margin-left: 4%;
            }

            label {
                display: block;
                @include font-size(18);
                font-weight: 300;
                line-height: 1.28; // 23px
                margin: 0 0 12px;
                @include opacity(0.5);
            }
        }
    }
}

.appointment-footer {
    padding: 57px 0 59px;

    .container {
        position: relative;
        z-index: 1;
    }

    p {
        @include font-size(36);
        font-weight: 300;
        line-height: 1.39; // 50px
        margin: 0 auto;
        max-width: 870px;
        text-align: center;
    }

    label {
        display: block;
        @include font-size(14);
        line-height: 1.5; // 21px
        margin: 23px 0 38px;
        text-align: center;

        a {
            color: $colorYellow;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        input {

        }
    }

    button {
        cursor: pointer;
    }
}

.static {
    padding-top: 40px;

    .content {
        @include clearfix;
        margin: 0 auto;
        max-width: 1020px;

        .timeline {
            display: inline-block;
            float: left;
            width: 25%;

            .icon {
                height: 130px;
                margin-bottom: 14px;
                width: 111px;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }

            .line {
                background-color: $colorYellow;
                border-radius: 2px;
                height: 200px;
                margin-left: 34px;
                width: 4px;
            }
        }

        .info {
            display: inline-block;
            float: right;
            width: 71.43%;

            h2 {
                color: $colorYellow;
                @include font-size(18);
                line-height: 1.67; // 30px
                margin: 19px 0 2px;
                text-transform: uppercase;
            }

            h1 {
                @include font-size(46);
                font-weight: 300;
                line-height: 1.22;
                margin: 0 0 16px;
            }

            p, ul {
                @include font-size(18);
                line-height: 1.67; // 30px
                margin: 0 0 30px;

                a {
                    color: $colorYellow;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            h3 {
                @include font-size(18);
                font-weight: 700;
                line-height: 1.67; // 30px
                margin: 0;
            }

            table {
                margin: 20px 0;
                width: 100%;

                tr {
                    th, td {
                        border: solid 1px $colorGrey;
                        @include font-size(18);
                        line-height: 1.67; // 30px
                        padding: 5px;
                        text-align: center;

                        &:first-child {
                            width: 20%;
                        }
                        &:first-child {
                            width: 20%;
                        }
                        &:last-child {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
}

.home {
    @include clearfix;

    .quote-block {
        padding-top: 128px;
    }
}

.home-placeholder {
    background-color: transparent;
    height: calc(100vh - 100px);
    position: relative;
    z-index: 1;
}

.hero {
    bottom: 0;
    left: 0;
    padding: 30vh 0 0;
    position: fixed;
    right: 0;
    top: 100px;
    z-index: -1;

    .icons {
        height: 64px;
        text-align: center;

        svg {
            display: inline-block;
            height: 100%;
            margin: 0 10px;
            width: auto;
        }
    }

    h1 {
        color: #fff;
        @include font-size(43);
        font-weight: 300;
        line-height: 1;
        margin: 27px 0 0;
        text-align: center;

        strong {
            font-weight: 700;
        }
    }

    .down {
        background: url(../img/arrow-yellow.svg) center center no-repeat #fff;
        background-size: 20px 12px;
        border-radius: 50%;
        bottom: 50px;
        display: block;
        height: 44px;
        left: calc(50% - 22px);
        position: absolute;
        text-indent: -9999px;
        animation: downButton 5s linear infinite;
        width: 44px;
    }
}

@keyframes downButton {
    0% {
        bottom: 60px;
    }

    10% {
        bottom: 50px;
    }

    20% {
        bottom: 60px;
    }

    30% {
        bottom: 50px;
    }

    90% {
        bottom: 50px;
    }

    100% {
        bottom: 60px;
    }
}

.home-diagnosis {
    padding: 146px 0 201px;
    z-index: 2;

    .container {
        position: relative;
        z-index: 1;
    }

    .icons {
        text-align: center;

        .icon {
            border: solid 4px $colorYellow;
            border-radius: 50%;
            display: inline-block;
            height: 150px;
            margin: 0 30px;
            text-align: left;
            width: 150px;

            &:first-child {
                padding: 37px 0 0 43px;

                svg {
                    height: 71px;
                    width: 58px;
                }
            }

            &:nth-child(2) {
                padding: 31px 0 0 18px;

                svg {
                    height: 77px;
                    width: 96px;
                }
            }

            &:last-child {
                padding: 38px 0 0 24px;

                svg {
                    height: 67px;
                    width: 96px;
                }
            }
        }
    }

    h2 {
        font-family: $fontLora;
        @include font-size(46);
        font-style: italic;
        font-weight: 400;
        line-height: 1.35; // 62px
        margin: 36px 0 11px 250px;
        max-width: 740px;
    }

    p {
        @include font-size(36);
        font-weight: 300;
        line-height: 1.39; // 50px
        margin: 0 0 30px 250px;
        max-width: 740px;
    }

    .btn {
        margin: 0 0 0 250px;
    }
}

.home-gallery {
    @include clearfix;
    padding-bottom: 80px;

    .grid {
        .item {
            &:nth-child(9n+1), &:nth-child(9n+3), &:nth-child(9n+4), &:nth-child(9n+8) {
                margin-left: 0;
            }

            &:nth-child(9n+2), &:nth-child(9n+7), &:nth-child(9n) {
                float: right;
            }
        }
    }

    .more {
        background: url(../img/ico-more-white.svg) center center no-repeat $colorYellow;
        background-size: 14px 14px;
        border-radius: 50%;
        display: block;
        height: 50px;
        margin: 50px auto 0;
        position: relative;
        text-indent: -9999px;
        width: 50px;
        z-index: 1;
    }
}

.home-services {
    padding: 170px 0 90px;

    .container {
        position: relative;
        z-index: 1;
    }

    h2 {
        color: #fff;
        @include font-size(36);
        font-weight: 300;
        line-height: 1.28;
        margin: 0 0 43px;
        text-align: center;

        &:before {
            content: '-';
            display: inline-block;
            margin-right: 10px;
        }

        &:after {
            content: '-';
            display: inline-block;
            margin-left: 10px;
        }
    }

    .list-services {
        @include clearfix;
        margin: 0 auto;
        max-width: 960px;
        position: relative;
        z-index: 1;

        .service {
            display: inline-block;
            float: left;
            margin: 0 0 98px 96px;

            &:nth-child(4n+1) {
                margin-left: 0;
            }

            &:first-child {
                .image {
                    padding: 20px 0 0 38px;

                    svg {
                        height: 109px;
                        width: 85px;
                    }
                }
            }

            &:nth-child(2) {
                .image {
                    padding: 33px 0 0 24px;

                    svg {
                        height: 84px;
                        width: 109px;
                    }
                }
            }

            &:nth-child(3) {
                .image {
                    padding: 67px 0 0 37px;

                    svg {
                        height: 33px;
                        width: 86px;
                    }
                }
            }

            &:nth-child(4) {
                .image {
                    padding: 30px 0 0 41px;

                    svg {
                        height: 98px;
                        width: 75px;
                    }
                }
            }

            &:nth-child(5) {
                .image {
                    padding: 26px 0 0 42px;

                    svg {
                        height: 99px;
                        width: 73px;
                    }
                }
            }

            &:nth-child(6) {
                .image {
                    padding: 25px 0 0 29px;

                    svg {
                        height: 101px;
                        width: 101px;
                    }
                }
            }

            &:nth-child(7) {
                .image {
                    padding: 30px 0 0 50px;

                    svg {
                        height: 98px;
                        width: 61px;
                    }
                }
            }

            &:last-child {
                .image {
                    padding: 28px 0 0 40px;

                    svg {
                        height: 98px;
                        width: 80px;
                    }
                }
            }

            .image {
                border: solid 4px #fff;
                border-radius: 50%;
                height: 166px;
                width: 166px;
            }

            h3 {
                color: #fff;
                @include font-size(20);
                line-height: 1.5; // 30px
                margin: 8px 0 0;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }

    .btn {
        background-color: #fff;
        color: $colorYellow;

        &:hover {
            color: #fff;
        }
    }
}

.page404 {
    .container {
        margin-top: 28px;
        padding-bottom: 110px;
        padding-top: 9vh;
        position: relative;
    }

    .icon {
        display: block;
        height: 251px;
        margin: 10px auto 0;
        width: 323px;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    h1 {
        color: $colorYellow;
        @include font-size(556);
        font-weight: 700;
        left: 0;
        line-height: 0.68;
        margin: 0;
        @include opacity(0.2);
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        z-index: 0;
    }

    p {
        font-family: $fontLora;
        @include font-size(46);
        font-style: italic;
        line-height: 1.35; // 62px
        margin: 20px auto 40px;
        max-width: 600px;
        position: relative;
        text-align: center;
        z-index: 1;
    }

    a {
        position: relative;
        z-index: 1;
    }
}

.gallery-wrapper {
    background-color: #fff;
    bottom: 0;
    display: none;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 6;

    .images-container {
        margin-top: 40px;
        overflow: hidden;
    }

    .images {
        overflow: hidden;
        @include transition(all 0.8s ease-in-out);
        width: 99999px;

        img {
            display: inline-block;
            float: left;
            width: 1158px;
        }
    }

    nav {
        @include clearfix;
        margin: 30px 0;

        a {
            background-color: $colorYellow;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 50%;
            display: inline-block;
            float: right;
            height: 50px;
            margin-left: 20px;
            text-indent: -9999px;
            width: 50px;

            &:hover {
                opacity: 0.5;
            }

            &.prev, &.next {
                background-image: url(../img/slider-arrow.svg);
                background-size: 16px 16px;
            }

            &.next {
                @include transformRotate(180deg);
                text-indent: 9999px;
            }

            &.close {
                background-image: url(../img/ico-close.svg);
                background-size: 12px 12px;
            }
        }
    }
}

.quote-block {
    background-color: #fff;
    @include clearfix;

    .container {
        padding-bottom: 35.5vh;
        padding-top: 12vh;
        position: relative;
        z-index: 1;

        .btn {
            margin: 36px 0 0 250px;
        }

        .down {
            background: url(../img/arrow.svg) center center no-repeat $colorYellow;
            background-size: 20px 12px;
            border-radius: 50%;
            bottom: 50px;
            display: block;
            height: 44px;
            left: calc(50% - 22px);
            position: absolute;
            text-indent: -9999px;
            width: 44px;
            z-index: 1;
        }
    }

    p {
        font-family: $fontLora;
        @include font-size(46);
        font-style: italic;
        line-height: 1.35; // 62px
        margin: 0 0 0 120px;
        max-width: 870px;
        padding: 45px 0 0 130px;
        position: relative;
        z-index: 1;

        &:before {
            background: url(../img/quotes.svg) no-repeat;
            background-size: cover;
            content: '';
            display: block;
            height: 92px;
            left: 0;
            position: absolute;
            top: 0;
            width: 94px;
        }
    }

    &.quote2 {
        .container {
            padding-bottom: 19vh;
            padding-top: 9vh;
        }

        p {
            font-family: $fontOpenSans;
            @include font-size(68);
            font-style: normal;
            font-weight: 300;
            line-height: 1.29; // 88px
            padding: 125px 0 0;
            text-align: center;

            &:before {
                background-image: url(../img/quotes-blue.svg);
                left: calc(50% - 47px);
            }
        }

        h5 {
            color: $colorBlue;
            @include font-size(24);
            font-weight: 400;
            line-height: 1.92; // 46px
            margin: 34px 0 90px;
            text-align: center;
            text-transform: uppercase;

            &:before {
                content: '-';
                display: inline-block;
                margin-right: 5px;
            }

            &:after {
                content: '-';
                display: inline-block;
                margin-left: 5px;
            }
        }
    }

    &.quote-about {
        p {
            margin: 0 0 0 72px;
            max-width: 925px;
            padding: 45px 0 0 120px;
        }
    }

    &.drquote {
        width: 100%;

        .container {
            padding: 132px 0 66px;

            p {
                font-family: $fontOpenSans;
                @include font-size(68);
                font-style: normal;
                font-weight: 300;
                line-height: 1.29; // 88px
                margin: 0 auto;
                padding: 126px 0 0;
                text-align: center;

                &:before {
                    left: calc(50% - 47px);
                    top: 0;
                }
            }

            span {
                color: $colorYellow;
                display: block;
                margin: 41px 0 95px;
                text-align: center;
                text-transform: uppercase;

                &:before {
                    content: '-';
                    display: inline-block;
                    margin-right: 5px;
                }

                &:after {
                    content: '-';
                    display: inline-block;
                    margin-left: 5px;
                }
            }

            .icons {
                height: 54px;
                text-align: center;

                svg {
                    display: inline-block;
                    height: 100%;
                    margin: 0 10px;
                    width: auto;

                    path, line, rect {
                        fill: $colorBlue;
                    }
                }
            }

            h4 {
                @include font-size(24);
                font-weight: 300;
                line-height: 1;
                margin: 27px 0 0;
                text-align: center;

                strong {
                    font-weight: 700;
                }
            }
        }
    }
}

.gallery-about {
    @include clearfix;
    margin: 53px 0 -30px;

    .grid {
        .item {
            &:nth-child(9n+1), &:nth-child(9n+4), &:nth-child(9n) {
                margin-left: 0;
            }

            &:nth-child(9n+2), &:nth-child(9n+3), &:nth-child(9n+8) {
                float: right;
            }

            &:nth-child(9n) {
                a {
                    display: block;
                    height: 100%;
                    padding: calc(50% - 25px);
                    width: 100%;

                    span {
                        background: url(../img/ico-more-white.svg) center center no-repeat $colorYellow;
                        background-size: 14px 14px;
                        border-radius: 50%;
                        display: block;
                        height: 50px;
                        text-indent: -9999px;
                        width: 50px;
                    }
                }
            }
        }
    }
}

.contact-block {
    padding: 130px 0 160px;

    .container {
        position: relative;
        z-index: 1;
    }

    &.newsletter {
        padding: 89px 0 111px;
    }

    h3 {
        color: $colorYellow;
        @include font-size(68);
        font-weight: 300;
        line-height: 1.3; // 88px
        margin: 0 0 16px;
        text-align: center;
    }

    p {
        @include font-size(36);
        font-weight: 300;
        line-height: 1.39; // 50px
        margin: 0 auto 27px;
        max-width: 800px;
        position: relative;
        text-align: center;
        z-index: 1;

        strong {
            font-weight: 400;
        }
    }

    form {
        > input[type="email"] {
            background-color: #fff;
            border: solid 1px $colorYellow;
            border-radius: 4px;
            color: $colorGrey;
            display: block;
            font-family: $fontOpenSans;
            @include font-size(14);
            line-height: 1.5; // 21px
            margin: 0 auto;
            max-width: 780px;
            padding: 15px 12px;
            width: 100%;
        }

        label {
            display: block;
            @include font-size(14);
            line-height: 1.5; // 21px
            margin: 23px 0 38px;
            text-align: center;

            a {
                color: $colorYellow;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        button {
            cursor: pointer;
        }
    }
}

.cookies {
    background-color: $colorYellow;
    bottom: 0;
    left: 0;
    padding: 26px 30px 16px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 5;

    h4 {
        color: #fff;
        @include font-size(13);
        font-weight: 700;
        line-height: 1.54; // 20px
        margin: 0 0 5px;
        text-transform: uppercase;
    }

    p {
        color: #fff;
        @include font-size(13);
        line-height: 1.54; // 20px
        margin: 0 auto 11px;
        max-width: 600px;

        a {
            color: #fff;
            font-weight: 700;
        }
    }
}

.btn {
    background-color: $colorYellow;
    border: none;
    border-radius: 22px;
    color: #fff;
    display: block;
    font-family: $fontOpenSans;
    @include font-size(14);
    font-style: normal;
    line-height: 1.71; // 24px
    margin: 0 auto;
    padding: 13px 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 188px;

    &:hover {
        background-color: $colorGrey;
    }
}

.flash-wrapper {
    background-color: #fff;
    bottom: 0;
    display: block;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 9vh;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 7;

    .alert {
        font-family: $fontLora;
        font-style: italic;
        margin: 0 auto;
        max-width: 600px;
        padding: 53px 0 0;
        position: relative;
        text-align: center;
        z-index: 1;

        h1 {
            @include font-size(46);
            line-height: 1.35; // 62px
            margin: 0;
            padding: 122px 0 0;
            position: relative;
            z-index: 1;

            &:before {
                background: url(../img/quotes.svg) no-repeat;
                background-size: cover;
                content: '';
                display: block;
                height: 92px;
                left: calc(50% - 47px);
                position: absolute;
                top: 0;
                width: 94px;
            }
        }

        p {
            @include font-size(26);
            line-height: 1.38; // 36px
            margin: 22px 0;
        }

        &.alert-danger {
            max-width: 720px;

            h1:before {
                background-image: url(../img/quotes-blue.svg);
            }

            a {
                background-color: $colorBlue;
                display: inline-block;
                margin: 0 15px;
                width: 115px;

                &:hover {
                    background-color: $colorGrey;
                }
            }
        }
    }
}

.background {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 0;

    .container {
        position: relative;
    }
}
